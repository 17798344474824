import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import VueToastr from 'vue-toastr'
import VueEcho from 'vue-echo-laravel'
import Pusher from 'pusher-js'

import vuetify from '@/plugins/vuetify'

import '@/mixins/global'

import '@/assets/css/app.scss'

import '@/plugins/v-mask'
import '@/plugins/vuetify-mask'
import '@/plugins/lodash'

import '@/components/components'

import '@/services/interceptors'
window.Pusher = Pusher

Vue.use(VueToastr, {
  defaultTimeout: 0,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  defaultPosition: 'toast-top-right',
  defaultCloseOnHover: false,
  defaultClassNames: ['animated', 'zoomInUp']
})

Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: 'myappkey',
  cluster: 'mt1',
  wsHost: process.env.VUE_APP_CHAT_SERVICE_IP,
  wsPort: process.env.VUE_APP_CHAT_SERVICE_PORT,
  forceTLS: false,
  disableStats: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import { getField } from 'vuex-map-fields'
// import notEmpty from '@/methods/notEmpty'

export default {
  getField,
  isRootUser: (state) => {
    return state.activeUser.role_id == 1 || state.activeUser.is_root == true
  },
  isGSuser: (state) => {
    return state.activeUser.role_id == 2 || state.activeUser.role_id == 3
  },
  isRealEstateUser: (state) => {
    return state.activeUser.role_id == 4 || state.activeUser.role_id == 5
  },
  userRole: (state) => {
    return state.activeUser.role_id
  },
  userRealEstate: (state) => {
    return state.activeUser.real_estate
  },
  activeUser: (state) => {
    return state.activeUser
  },
  requestNumber: (state) => {
    return state.request.numero ?? '--'
  },
  requestRevision: (state) => {
    return state.request.revisao ?? null
  },
  requestDate: (state) => {
    // var date = new Date()

    // var time = date.getHours().toString() + 'h' + date.getMinutes().toString() + 'min'
    // var day = date.getDate().toString()

    // var dayF = (day.length == 1) ? '0' + day : day
    // var month = (date.getMonth() + 1).toString()
    // var monthF = (month.length == 1) ? '0' + month : month
    // var yearF = date.getFullYear()

    // return dayF + "/" + monthF + "/" + yearF + ' às ' + time;
    return state.request.data_cadastro ?? '--'
  },
  requestSituation: (state) => {
    if (state.request.situacao_id && state.situationsList.length) {
      const situation = state.situationsList.find(st => {
        return st.id == state.request.situacao_id
      })
      return situation ? situation.nome : ''
    }
    return 'Carregando..'
  },
  requestServiceTypeText: (state) => {
    if (state.request.tipo_servico_id && state.serviceTypeList.length) {
      const serviceType = state.serviceTypeList.find(st => {
        return st.id == state.request.tipo_servico_id
      })
      return serviceType ? serviceType.nome : ''
    }
    return 'Carregando..'
  },
  requestServiceType: (state) => {
    return state.request.tipo_servico_id
  },
  requestAttendant: (state) => {
    const attendent = state.attendantsList.find(a => {
      return a.value == state.request.usuario_id
    })
    return attendent && attendent.text ? attendent.text : null
  },
  requestsList: (state) => {
    return state.requestsList
  },
  requestPendencies: (state) => {
    return state.request.pendencies
  },
  getAnalysisList: (state) => {
    return state.analysisList
  },
  requestPendencie: (state) => (id) => {
    return state.request.pendencies[id]
  },
  statesList: (state) => {
    return state.statesList
  },
  citiesList: (state) => {
    return state.citiesList
  },
  maritalStatusList: (state) => {
    return state.maritalStatusList
  },
  incomeSourceList: (state) => {
    return state.incomeSourceList.filter((i) => i.id !== 15)
  },
  situationsList: (state) => {
    return state.situationsList
  },
  serviceTypeList: (state) => {
    return state.serviceTypeList
  },
  financialProfileList: (state) => {
    return state.financialProfileList
  },
  documentTypeList: (state) => {
    return state.documentTypeList
  },
  pretensionSolicitationList: (state) => {
    return state.pretensionSolicitationList
  },
  attendantsList: (state) => {
    return state.attendantsList
  },
  realStateList: (state) => {
    return state.realStateList
  },
  realStateSelectList: (state) => {
    return state.realStateSelectList
  },
  rolesSelectList: (state) => {
    return state.rolesSelectList
  },
  userList: (state) => {
    return state.userList
  },
  feedbackModelList: (state) => {
    return state.feedbackModelList
  },
  tenants: (state) => {
    return state.request.tenants
  },
  tenant: (state) => (id) => {
    return state.request.tenants[id]
  },
  request: (state) => {
    return state.request
  },
  newTenant: (state) => {
    return state.newTenant
  },
  inRegistration (state) {
    return state.request.situacao_id == 1
  },
  inAnalysis (state) {
    return state.request.situacao_id == 2
  },
  withPendency (state) {
    return state.request.situacao_id == 3
  },
  makesUpIncome (state) {
    return !state.newTenant.financialProfile.compoe_renda
  },
  complementaryDocumentTypeList: (state) => {
    return state.complementaryDocumentTypeList
  },
  generatedDocuments: (state) => {
    return state.generatedDocumentsList
  },

  requiresNewAnalysis: (state) => {
    return state.requiresNewAnalysis
  },

  realEstateConditionTerm: (state) => {
    return state.realEstateConditionTerm
  },

  availableToReopen: (state) => {
    return state.request.situacao_id == 4 || state.request.situacao_id == 5
  },

  nacionalidadeList: (state) => {
    return state.nacionalidadeList
  },

}

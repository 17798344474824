import Vue from 'vue'

import exists from '@/methods/exists'
import notEmpty from '@/methods/notEmpty'
import isObject from '@/methods/isObject'
import fileDownload from '@/methods/fileDownload'
import fileShow from '@/methods/fileShow'

Vue.mixin({
	methods: {
    exists,
    notEmpty,
    isObject,
    fileDownload,
    fileShow
	}
})

import axios from 'axios'

const baseUrl = process.env.VUE_APP_BACK_URL

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default api

/* eslint-disable no-undef */
import api from '@/services/api'
import axios from 'axios'

export default {

  async getActiveUser (context) {
    await api.get('api/user/active')
      .then(resp => (
        context.commit('setActiveUser', resp.data.data)
      ))
  },

  async getRequestsList (context) {
    await api.get('api/solicitation/list')
      .then(resp => (
        context.commit('setRequestsList', resp.data)
      ))
  },

  async getDocumentGeneratedList (context, payload) {
    await api.post('api/solicitation/generateReports', payload)
      .then(resp => (
        context.commit('setGeneratedDocumentsList', resp.data)
      ))
  },

  async getRequest (context, payload) {
    await api.post('api/solicitation/' + payload.id, payload)
      .then(resp => (
        context.commit('setRequest', resp.data.data)
      ))
  },

  async getStatesList (context) {
    await api.get('api/estado/list')
      .then(resp => (
        context.commit('setStatesList', resp.data)
      ))
  },

  async getCitiesList (context, param) {
    await api.get('api/cidade/list', {
      params: {
        state: param
      }
    })
      .then(resp => (
        context.commit('setCitiesList', resp.data)
      ))
  },

  async getMaritalStatusList (context) {
    if (!context.getters.maritalStatusList.length) {
      await api.get('api/estado-civil/list')
        .then(resp => (
          context.commit('setMaritalStatusList', resp.data)
        ))
    }
  },

  async getIncomeSourceList (context) {
    if (!context.getters.incomeSourceList.length) {
      await api.get('api/origem-renda/list')
        .then(resp => (
          context.commit('setIncomeSourceList', resp.data)
        ))
    }
  },

  async getSituationsList (context) {
    if (!context.getters.situationsList.length) {
      await api.get('api/situation/list')
        .then(resp => (
          context.commit('setSituationsList', resp.data)
        ))
    }
  },

  async getServiceTypeList (context) {
    if (!context.getters.serviceTypeList.length) {
      await api.get('api/service-type/list')
        .then(resp => (
          context.commit('setServiceTypeList', resp.data)
        ))
    }
  },

  async getFinancialProfileList (context) {
    if (!context.getters.financialProfileList.length) {
      await api.get('api/financial-profile/list')
        .then(resp => (
          context.commit('setFinancialProfileList', resp.data)
        ))
    }
  },

  async getDocumentTypeList (context, payload) {
    if (!context.getters.documentTypeList.length) {
      await api.get('api/document-type/list', payload)
        .then(resp => (
          context.commit('setDocumentTypeList', resp.data)
        ))
    }
  },

  async getPretensionSolicitationList (context) {
    if (!context.getters.pretensionSolicitationList.length) {
      await api.get('api/pretension-solicitation/list')
        .then(resp => (
          context.commit('setPretensionSolicitationList', resp.data)
        ))
    }
  },

  async getFeedbackModelList (context, payload) {
    await api.get('api/model-feedback/list', payload)
      .then(resp => (
        context.commit('setFeedbackModelList', resp.data)
      ))
  },

  async getAdrressByCep (context, payload) {
    let address = ''
    await axios.get('https://viacep.com.br/ws/' + payload + '/json/')
      .then(resp => (
        address = resp.data
      ))

    return address
  },

  async getRealty (context, payload) {
    let realty = ''
    await api.post('api/realty', {
      code: payload
    })
      .then(resp => (
        realty = resp.data
      ))

    return realty
  },

  async getPerson (context, payload) {
    let person = ''
    await api.post('api/person', {
      cpf: payload
    })
      .then(resp => (
        person = resp.data
      ))

    return person
  },

  async saveTenant (context, payload) {
    const person = context.state.newTenant
    person.solicitacao_id = context.state.request.id

    await api.post('api/person/save', person)
      .then(resp => (
        context.commit('setNewTenantId', resp.data.pessoa_id)
      ))
  },

  async saveRequest (context, param) {
    const solicitation = context.getters.request
    await api.post('api/solicitation/save', {
      solicitation
    })
      .then(resp => (
        context.commit('setRequest', resp.data)
      ))
  },

  async savePersonFiles (context, payload) {
    var formData = new FormData()

    payload.forEach((file, i) => {
      formData.append('files[' + i + ']', file)
    })

    formData.append('person_id', context.state.newTenant.id)
    formData.append('solicitation_id', context.state.request.id)

    await api.post('api/person-document/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(resp => (
        context.commit('setNewTenantFiles', resp.data)
      ))
  },

  async deletePersonFile (context, payload) {
    let response = ''
    await api.post('api/person-document/delete', payload)
      .then(resp => (
        response = resp.data
      ))
    return response
  },

  async downloadPersonFile (context, payload) {
    let response = ''
    await api.get('api/person-document/download/' + payload, {
      responseType: 'arraybuffer'
    })
      .then(resp => {
        response = resp
      })
    return response
  },

  async showPersonFile (context, payload) {
    let response = ''
    await api.get('api/person-document/show/' + payload)
      .then(resp => {
        response = resp
      })
    return response
  },

  async validateRealty (context, param) {
    const solicitation = context.getters.request
    let response = ''
    await api.post('api/realty/validate', solicitation.realty)
      .then(resp => (
        response = resp
      ))
      .catch(error => {
        response = error.response
      })
    return response
  },

  async validateTenant (context, param) {
    const person = param
    let response = ''
    await api.post('api/person/validate', person)
      .then(resp => (
        response = resp
      ))
      .catch(error => {
        response = error.response
      })
    return response
  },

  async savePendency (context, payload) {
    payload.solicitacao_id = context.state.request.id

    await api.post('api/solicitation-revision/save', payload)
      .then(resp => (
        context.commit('setRequestPendencies', resp.data)
      ))
  },

  async changeSituation (context, payload) {
    const params = {}
    params.situation_id = payload
    params.solicitation_id = context.state.request.id

    await api.post('api/solicitation/situation/change', params)
      .then(resp => (
        context.commit('setRequestSituation', resp.data.situacao_id)
      ))
  },

  async deleteTenant (context, payload) {
    const params = {}
    params.person_id = payload
    params.solicitation_id = context.state.request.id

    await api.post('api/person-solicitation/delete', params)
      .then(resp => (
        context.commit('removeTenantFromTenants', resp.data.pessoa_id)
      ))
  },

  async cancelSolicitation (context, payload) {
    const params = {}
    params.justification = payload
    params.solicitation_id = context.state.request.id
    params.role_id = context.state.activeUser.role_id

    await api.post('api/solicitation/cancel', params)
      .then(resp => (
        context.commit('setRequestSituation', resp.data.situacao_id)
      ))
  },

  async saveRealState (context) {
    var formData = new FormData()

    var files = _.cloneDeep(context.state.realState.documentos.files)
    var realState = JSON.stringify(_.cloneDeep(context.state.realState), true)

    files.forEach((file, i) => {
      formData.append('files[' + i + ']', file)
    })

    formData.append('form_data', realState)

    await api.post('api/client/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(resp => (
        console.log(resp.data)
      ))
  },

  async getRealStateList (context) {
    await api.get('api/client/list')
      .then(resp => (
        context.commit('setRealStateList', resp.data)
      ))
  },

  async getRealState (context, payload) {
    await api.get('api/client/' + payload)
      .then(resp => (
        context.commit('setRealState', resp.data.data)
      ))
  },

  async getUserList (context) {
    await api.get('api/user/list')
      .then(resp => (
        context.commit('setUserList', resp.data)
      ))
  },

  async getUser (context, payload) {
    await api.get('api/user/' + payload)
      .then(resp => (
        context.commit('setUser', resp.data.data)
      ))
  },

  async saveUser (context, payload) {
    const user = context.state.user
    user.solicitacao_id = context.state.request.id

    await api.post('api/user/save', user)
      .then(resp => (
        console.log(resp.data)
        // context.commit('setNewTenantId', resp.data.pessoa_id)
      ))
  },

  async getRealStateSelectList (context) {
    await api.get('api/client/select-list')
      .then(resp => (
        context.commit('setRealStateSelectList', resp.data)
      ))
  },

  async getRolesSelectList (context) {
    await api.get('api/role/select-list')
      .then(resp => (
        context.commit('setRolesSelectList', resp.data)
      ))
  },

  async deleteUser (context, payload) {
    await api.post('api/user/delete/' + payload)
      .then(resp => (
        context.dispatch('getUserList')
      ))
  },

  async getAttendantsList (context) {
    await api.get('api/user-client/select-list')
      .then(resp => (
        context.commit('setAttendantsList', resp.data)
      ))
  },

  async deleteRealStateDocument (context, payload) {
    let response = ''
    await api.post('api/client/document/delete', payload)
      .then(resp => (
        response = resp.data
      ))
    return response
  },

  async downloadRealStateDocument (context, payload) {
    let response = ''
    await api.get('api/client/download/' + payload, {
      responseType: 'arraybuffer'
    })
      .then(resp => {
        response = resp
      })
    return response
  },

  async showRealStateDocument (context, payload) {
    let response = ''
    await api.get('api/client/show/' + payload)
      .then(resp => {
        response = resp
      })
    return response
  },

  async getComplementaryDocumentTypeList (context, payload) {
    if (!context.getters.complementaryDocumentTypeList.length) {
      await api.get('api/complementary-document-type/list', payload)
        .then(resp => (
          context.commit('setComplementaryDocumentTypeList', resp.data)
        ))
    }
  },

  async saveFeedbackModel (context, payload) {
    await api.post('api/model-feedback/save', payload)
      .then(resp => (
        console.log(resp.data)
        // context.commit('setNewTenantId', resp.data.pessoa_id)
      ))
  },

  async deleteFeedbackModel (context, payload) {
    let response = ''
    await api.post('api/model-feedback/delete/' + payload)
      .then(resp => (
        response = resp.data
      ))
    return response
  },

  async createAnalysis (context, payload) {
    let response = ''
    await api.post('/api/analysis/create', payload)
      .then(resp => (
        response = resp
      ))
    return response
  },

  async getAnalysisList (context, payload) {
    await api.get('/api/analysis/find-by-solicitation/' + payload)
      .then(resp => (
        context.commit('setAnalysisList', resp.data)
      ))
  },

  async getAnalysisReport (context, payload) {
    let response = ''
    await api.get('/api/analysis/print-fc-report/' + payload)
      .then(resp => (
        response = resp
      ))
    return response
  },

  async verifyRequiresNewAnalysis (context, payload) {
    const response = ''
    await api.get('/api/analysis/requires-new-analysis/' + payload)
      .then(resp => (
        context.commit('setRequiresNewAnalysis', { value: resp.data, processed: true })
      ))
    return response
  },

  resetRequiresNewAnalysis (context, payload) {
    context.commit('setRequiresNewAnalysis', { value: false, processed: false })
  },

  async getrealEstateConditionTerm (context, payload) {
    await api.post('api/client/generateReport', payload)
      .then(resp => (
        context.commit('setRealEstateConditionTerm', resp.data)
      ))
  },
  
  async getNacionalidadeList (context) {
    if (!context.getters.nacionalidadeList.length) {
      await api.get('api/nacionalidade/list')
        .then(resp => (
          context.commit('setNacionalidadeList', resp.data)
        ))
    }
  },

}

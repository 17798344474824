<template>
<v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="auto"
    min-width="auto"
>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label=label
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
        :loading=loading
        v-mask="'##/##/####'"
        :readonly=readonly
      ></v-text-field>
    </template>
    <v-date-picker
      show-adjacent-months
      v-model="date"
      locale="pt-br"
      no-title
      scrollable
      @input="menu1 = false"
      v-if=!readonly
    ></v-date-picker>
</v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    date: null,
    dateFormatted: null,
    menu1: false
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
      this.$emit('input', val)
    }
    // dateFormatted(val) {
    //   this.$emit('input', val)
    // }
  },
  created () {
    this.date = this.value
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

import Vue from 'vue'
import api from '@/services/api'

const BASE_URL = process.env.VUE_APP_CHAT_SERVICE_URL
const APLICACAO_ID = process.env.VUE_APP_CHAT_SERVICE_APLICACAO_ID

export default {

  chatAuth (context) {
    const activeUser = context.rootGetters.activeUser
    return api.post(BASE_URL + '/api/auth', {
      email: activeUser.email,
      nome: activeUser.name,
      descricao: activeUser.real_estate,
      fotoUrl: activeUser.photo,
      aplicacaoId: APLICACAO_ID,
      instalacaoId: activeUser.instalacao_id
    }).then(resp => {
      context.commit('setChatUser', resp.data)
    })
  },

  listenChat (context) {
    const chatUser = context.getters.getChatUser
    Vue.prototype.$echo.channel(chatUser.codigo)
      .listen('.NewMessage', (payload) => {
        const usuarioDestinoId = context.getters.getUsuarioDestinoId
        context.dispatch('myChats')
        if (usuarioDestinoId && usuarioDestinoId === payload.fromUserId) {
          context.commit('pushNewMessage', {
            type: 'text',
            author: 'client',
            data: {
              text: payload.message
            }
          })
        }
      })
      .listen('.TakeChat', (payload) => {
        context.commit('setUsuarioDestinoId', payload.userId)
      })
      .listen('.SendNotification', (payload) => {
        context.commit('pushNewNotification', payload)
      })
  },

  sendMessage (context, { _message, _usuarioDestinoId = null }) {
    const chatUser = context.getters.getChatUser
    return api.post(BASE_URL + '/api/message/new', {
      aplicacaoId: APLICACAO_ID,
      message: _message,
      usuarioOrigemId: chatUser.id,
      usuarioDestinoId: _usuarioDestinoId
    })
  },

  getAllMessages (context, _usuarioDestinoId = null) {
    const chatUser = context.getters.getChatUser
    const activeUser = context.rootGetters.activeUser
    context.commit('cleanMessageList')
    context.commit('setHasLoadingAllMessages', true)
    return api.post(BASE_URL + '/api/message/all', {
      aplicacaoId: APLICACAO_ID,
      usuarioOrigemId: chatUser.id,
      usuarioDestinoId: _usuarioDestinoId
    }).then(resp => {
      resp.data.forEach(data => {
        if (data.usuario_origem.email !== activeUser.email) {
          context.commit('setUsuarioDestinoId', data.usuario_origem.id)
          context.commit('setUsuarioDestinoNome', data.usuario_origem.nome)
        }
        context.commit('pushNewMessage', {
          type: 'text',
          author: data.usuario_origem.email === activeUser.email ? 'me' : 'client',
          data: {
            text: data.mensagem,
            date: data.created_at
          }
        })
      })
    }).finally(() => {
      context.commit('setHasLoadingAllMessages', false)
    })
  },

  waitingChats () {
    return api.post(BASE_URL + '/api/message/waiting', {
      aplicacaoId: APLICACAO_ID
    })
  },

  myChats (context) {
    const activeUser = context.rootGetters.activeUser
    api.post(BASE_URL + '/api/message/my-chats', {
      usuario: activeUser.email,
      aplicacaoId: APLICACAO_ID,
      instalacaoId: activeUser.instalacao_id
    }).then(resp => context.commit('setMyChats', resp.data))
  },

  takeChat (context, _usuarioOrigemId) {
    const activeUser = context.rootGetters.activeUser
    return api.post(BASE_URL + '/api/message/take-chat', {
      usuarioOrigemId: _usuarioOrigemId,
      usuarioDestino: activeUser.email,
      aplicacaoId: APLICACAO_ID,
      instalacaoId: activeUser.instalacao_id
    })
  }

}

<template>
  <div>
    <v-text-field-money
      v-model="valueComputed"
      v-bind:label="label"
      v-bind:properties="{
        prefix: 'R$',
        disabled: disabled,
        outlined: outlined,
        clearable: false,
        placeholder: ' ',
        required:required,
        loading:loading,
        readonly:readonly,
        messages:messages
      }"
      v-bind:options="{
        locale: 'pt-BR',
        length: length,
        precision: precision,
        empty: null,
      }"
    />
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      default: 11
    },
    precision: {
      type: Number,
      default: 2
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    value: {
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    messages: {
      type: [String, Array],
      default: null
    }
  },
  data: () => ({
    //
  }),
  computed: {
    valueComputed: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    }
  },

};
</script>

export default {

  setChatUser: (state, payload) => {
    state.chatUser = payload
  },

  pushNewMessage: (state, payload) => {
    state.messageList.push(payload)
  },

  cleanMessageList: (state) => {
    state.messageList = []
  },

  setUsuarioDestinoId: (state, payload) => {
    state.usuarioDestinoId = payload
  },

  pushNewNotification: (state, payload) => {
    let notificationsSameUser = state.notificationList.filter(notification => notification.from.nome == payload.from.nome);
    let notificationList = state.notificationList;
    notificationsSameUser.forEach(element => {
      let index = notificationList.indexOf(element);
      if (index > -1) {
        notificationList.splice(index,1);
      }
    });
    state.notificationList = [payload, ...notificationList];
    state.hasNewNotification = true;
  },

  setHasNewNotification: (state, payload) => {
    state.hasNewNotification = payload
  },

  setMyChats: (state, payload) => {
    state.myChats = payload
  },

  setHasLoadingAllMessages: (state, payload) => {
    state.hasLoadingAllMessages = payload
  },

  setUsuarioDestinoNome: (state, payload) => {
    state.usuarioDestinoNome = payload
  }

}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const Home = () => import('../views/Home/index.vue')
const Requests = () => import('../views/Requests/index.vue')
const NewRequest = () => import('../views/NewRequest/index.vue')
const RequestAbstract = () => import('../views/RequestAbstract/index.vue')
const RealEstate = () => import('../views/RealEstate/index.vue')
const NotFound = () => import('../views/Errors/NotFound.vue')
const Login = () => import('../views/Login/index.vue')
const Main = () => import('../views/Main/index.vue')
const User = () => import('../views/User/index.vue')
const FeedbackModel = () => import('../views/FeedbackModel/index.vue')
const RentalSimulator = () => import('../views/RentalSimulator/index.vue')
const Chats = () => import('../views/Chats/index.vue')

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '/requests',
        name: 'Requests',
        alias: '/solicitacoes',
        component: Requests
      },
      {
        path: '/request/new',
        name: 'NewRequest',
        component: NewRequest
      },
      {
        path: '/request/edit',
        name: 'EditRequest',
        component: NewRequest
      },
      {
        path: '/request/abstract',
        name: 'RequestAbstract',
        component: RequestAbstract
      },
      {
        path: '/real-estate/list',
        name: 'RealEstate',
        component: RealEstate
      },
      {
        path: '/users',
        name: 'User',
        component: User
      },
      {
        path: '/rental-simulator',
        name: 'RentalSimulator',
        component: RentalSimulator
      },
      {
        path: '/feedback-model/list',
        name: 'FeedbackModel',
        component: FeedbackModel
      },
      {
        path: '/chats',
        name: 'Chats',
        component: Chats
      }
    ]
  },
  {
    path: '/esqueci-minha-senha/:token',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes
})

router.beforeEach((to, from, next) => {
  const hasToken = !!sessionStorage.getItem(process.env.VUE_APP_OAUTH_ACCESS_TOKEN_KEY)

  if (to.name === 'EsqueciMinhaSenha' && !hasToken) next({ name: 'Login' })
  else if (to.name !== 'Login' && !hasToken) next({ name: 'Login' })
  else if (to.name === 'Login' && hasToken) next({ name: 'Requests' })
  else next()
})

export default router

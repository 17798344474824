export const defaultNewTenantFile = () => {
  return {
    id: null,
    nome_arquivo: '',
    arquivo_url: '',
    observacao: '',
    tipo_documento_id: null,
    pendencia: {
      is_conforme: false,
      observacao: ''
    },
    is_excluido: false
  }
}

export const defaultNewTenant = () => {
  return {
    id: null,
    personalData: {
      nome: null,
      cpf: null,
      cnh: null,
      rg: null,
      data_nascimento: null,
      profissao: null,
      estado_civil_id: null,
      pretensao_id: null,
      vai_morar: true,
      email: null,
      telefone: null,
      observacoes: null,
      tipo_documento_complementar_id: null,
      numero_doc_complementar: null,
      id_nacionalidade: null
    },
    financialProfile: {
      compoe_renda: true,
      perfil_financeiro_id: null,
      valor_renda: null,
      origem_renda_id: null,
      outras_rendas: [],
      origem_outras_rendas: [],
      enviar_para_analise: false
    },
    address: {
      ja_cadastrado: true,
      locatario: null,
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      estado_id: null,
      cidade_id: null
    },
    files: []
  }
}

export const defaultNewRealState = () => {
  return {
    id: null,
    codigo: null,
    nome: null,
    cpf_cnpj: null,
    creci: null,
    logo_url: null,
    telefone: null,
    email: null,
    nome_contato: null,
    cep: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    estado_id: null,
    cidade_id: null,
    tipo_cliente_id: 1,
    documentos: {
      id: [],
      files: [],
      file_types: [],
      observations: []
    }
  }
}

export const defaultNewUser = () => {
  return {
    id: null,
    nome: null,
    email: null,
    senha: null,
    confirmacao_senha: null,
    role_id: null,
    cliente_id: null
  }
}

export const defaultRequest = () => {
  return {
    id: null,
    data_cadastro: null,
    numero: null,
    situacao_id: 1,
    tipo_servico_id: null,
    usuario_id: null,
    observacao: '',
    revisao: null,
    realty: {
      id: null,
      codigo: '',
      cpf_cnpj_proprietario: '',
      nome_proprietario: '',
      valor_aluguel: '',
      valor_condominio: '',
      valor_iptu: '',
      valor_agua: '',
      valor_luz: '',
      valor_seguro_incendio: '',
      tipo_imovel: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      estado_id: '',
      cidade_id: ''
    },
    pendencies: [],
    tenants: []
  }
}

export default {
  activeUser: {
    id: null,
    name: null,
    email: null,
    real_estate: null,
    photo: null,
    client_id: null,
    role_id: 1 // 1 - GS, 2 - Imob.
  },

  request: defaultRequest(),
  newTenant: defaultNewTenant(),
  realState: defaultNewRealState(),
  user: defaultNewUser(),

  incomeSourceList: [],
  statesList: [],
  citiesList: [],
  maritalStatusList: [],
  requestsList: [],
  situationsList: [],
  serviceTypeList: [],
  financialProfileList: [],
  documentTypeList: [],
  pretensionSolicitationList: [],
  attendantsList: [],
  realStateList: [],
  userList: [],
  realStateSelectList: [],
  rolesSelectList: [],
  complementaryDocumentTypeList: [],
  feedbackModelList: [],
  analysisList: [],
  generatedDocumentsList: [],
  requiresNewAnalysis: {
    value: false,
    processed: false
  },
  realEstateConditionTerm: [],
  nacionalidadeList: []
}

export default {

  getChatUser: (state) => {
    return state.chatUser
  },

  getMyChats: (state) => {
    return state.myChats
  },

  getMessageList: (state) => {
    return state.messageList
  },

  getUsuarioDestinoId: (state) => {
    return state.usuarioDestinoId
  },

  getNotificationList: (state) => {
    return state.notificationList
  },

  getHasNewNotification: (state) => {
    return state.hasNewNotification
  },

  getHasLoadingAllMessages: (state) => {
    return state.hasLoadingAllMessages
  },
  getUsuarioDestinoNome: (state) => {
    return state.usuarioDestinoNome
  }

}

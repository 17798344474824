var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field-money',{attrs:{"label":_vm.label,"properties":{
      prefix: 'R$',
      disabled: _vm.disabled,
      outlined: _vm.outlined,
      clearable: false,
      placeholder: ' ',
      required:_vm.required,
      loading:_vm.loading,
      readonly:_vm.readonly,
      messages:_vm.messages
    },"options":{
      locale: 'pt-BR',
      length: _vm.length,
      precision: _vm.precision,
      empty: null,
    }},model:{value:(_vm.valueComputed),callback:function ($$v) {_vm.valueComputed=$$v},expression:"valueComputed"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default function(response) {

  const url = response.data

  const link = document.createElement('a')
  link.href = url
  link.target = '_blank';

  document.body.appendChild(link)
  link.click()
  link.remove()
}

import axios from 'axios'

export default {
  login (credentials) {
    const { username, password } = credentials
    return axios
      .post(process.env.VUE_APP_BACK_URL + '/oauth/token', {
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        grant_type: 'password',
        password: password,
        username: username
      })
      .then(function (response) {
        const access_token = response.data.access_token
        // const refresh_token = response.data.refresh_token
        sessionStorage.setItem(
          process.env.VUE_APP_OAUTH_ACCESS_TOKEN_KEY,
          access_token
        )
        // sessionStorage.setItem(
        //   process.env.VUE_APP_OAUTH_REFRESH_TOKEN_KEY,
        //   refresh_token
        // )

        return response
      })
      .catch(function (error) {
        return error.response
      })
  },
  logout () {
    sessionStorage.removeItem(process.env.VUE_APP_OAUTH_ACCESS_TOKEN_KEY)
    // sessionStorage.removeItem(process.env.VUE_APP_OAUTH_REFRESH_TOKEN_KEY)
  }
}

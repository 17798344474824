import Vue from 'vue'
import api from '@/services/api'
import auth from '@/services/auth'
import router from '@/router'

Vue.prototype.$http = api

// api.defaults.timeout = 10000;
api.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem(process.env.VUE_APP_OAUTH_ACCESS_TOKEN_KEY)
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          auth.logout()
          router.replace({
            path: '/login'
          })
          break
        // default:
        //   router.replace({
        //     path: `/error/${error.response.status}`,
        //   });
        //   break;
      }
      return Promise.reject(error)
    }
  }
)

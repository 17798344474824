export default {
  chatUser: {},
  messageList: [],
  usuarioDestinoId: null,
  myChats: [],
  notificationList: [],
  hasNewNotification: false,
  hasLoadingAllMessages: false,
  usuarioDestinoNome: null
}

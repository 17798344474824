import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00A79D",
        primarydark: "#00877F",
        error: '#C65447',
        success: '#00B780',
        alert: '#E49963',
        dark: '#1F1F1F',
        white: '#ffffff',
        login_green: '#00A79D',
        darkgray: '#b3b3b3',
      },
    },
  }
})

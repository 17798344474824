<template>
  <v-row>
    <v-switch
      v-model="state"
      color="success"
      :class="!state ? 'multi-color-switch' : ''"
      class="ma-0 pa-0"
      hide-details
      :readonly=readonly
      :disabled=disabled
    ></v-switch>
    <v-text-field
      dense
      v-model="pendencie"
      class="ma-0 pa-0"
      hide-details
      placeholder="Informe o parecer"
      v-if=!state
      :readonly=readonly
      :disabled=disabled
    ></v-text-field>
  </v-row>
</template>

<script>
export default {
  name: "MultiColorSwitch",
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: {
        is_conforme: false,
        observacao: ""
      },
    },
  },
  data: () => ({
    state: false,
    pendencie: ""
  }),
  created() {
    this.state = this.value.is_conforme,
    this.pendencie = this.value.observacao
  },
  watch: {
    state (val) {
      this.$emit('input', this.getData())
    },
    pendencie (val) {
      this.$emit('input', this.getData())
    },
  },
  methods: {
    getData() {
      return {
        is_conforme: this.state,
        observacao: this.pendencie,
      }
    }
  },
};
</script>

<style>
div.multi-color-switch
  > div
  > div
  > div
  > div.v-input--selection-controls__ripple {
  color: red !important;
}
div.multi-color-switch > div > div > div > div.v-input--switch__track {
  color: rgb(238, 94, 94) !important;
}
div.multi-color-switch > div > div > div > div.v-input--switch__thumb {
  color: rgb(238, 94, 94) !important;
}
</style>

import { updateField } from 'vuex-map-fields'
import { defaultNewTenant, defaultRequest, defaultNewTenantFile, defaultNewRealState, defaultNewUser } from './state'

export default {
  updateField,
  setActiveUser: (state, payload) => {
    state.request.usuario_id = payload.usuario_id
    return state.activeUser = payload
  },
  setActiveUserRole: (state, payload) => {
    return state.activeUser.role_id = payload
  },
  setRequest: (state, payload) => {
    Object.assign(state.request, payload)
  },
  setRequestSituation: (state, payload) => {
    return state.request.situacao_id = payload
  },
  setServiceType: (state, payload) => {
    return state.request.tipo_servico_id = payload
  },
  setRequestPendencies: (state, payload) => {
    const idx = state.request.pendencies.findIndex((p, i) => {
      return p.numero == payload.numero
    })

    if (idx > -1) {
      return state.request.pendencies[idx] = payload
    }

    return state.request.pendencies.push(payload)
  },
  setRealState: (state, payload) => {
    Object.assign(state.realState, payload)
  },
  setUser: (state, payload) => {
    Object.assign(state.user, payload)
  },

  setStatesList: (state, payload) => {
    return state.statesList = payload
  },
  setCitiesList: (state, payload) => {
    return state.citiesList = payload
  },
  setMaritalStatusList: (state, payload) => {
    return state.maritalStatusList = payload
  },
  setIncomeSourceList: (state, payload) => {
    return state.incomeSourceList = payload
  },
  setSituationsList: (state, payload) => {
    return state.situationsList = payload
  },
  setRequestsList: (state, payload) => {
    Object.assign(state.request, defaultRequest())
    state.request.usuario_id = state.activeUser.id
    return state.requestsList = payload
  },
  resetRequestsList: (state) => {
    return state.requestsList = []
  },
  setServiceTypeList: (state, payload) => {
    return state.serviceTypeList = payload
  },
  setFinancialProfileList: (state, payload) => {
    return state.financialProfileList = payload
  },
  setDocumentTypeList: (state, payload) => {
    return state.documentTypeList = payload
  },
  setPretensionSolicitationList: (state, payload) => {
    return state.pretensionSolicitationList = payload
  },
  setRealStateList: (state, payload) => {
    return state.realStateList = payload
  },
  setUserList: (state, payload) => {
    return state.userList = payload
  },
  setRealStateSelectList: (state, payload) => {
    return state.realStateSelectList = payload
  },
  setRolesSelectList: (state, payload) => {
    return state.rolesSelectList = payload
  },
  setAttendantsList: (state, payload) => {
    return state.attendantsList = payload
  },
  setFeedbackModelList: (state, payload) => {
    return state.feedbackModelList = payload
  },

  addTenantToTenants: (state) => {
    let newT = _.cloneDeep(state.newTenant)
    Object.assign(state.newTenant, defaultNewTenant())
    return state.request.tenants.push(newT)
  },
  resetNewTenant: (state) => {
    Object.assign(state.newTenant, defaultNewTenant())
  },
  setNewTenant: (state, payload) => {
    Object.assign(state.newTenant, payload)
  },
  setNewTenantId: (state, payload) => {
    state.newTenant.id = payload
  },
  resetRequest: (state) => {
    Object.assign(state.request, defaultRequest())
    state.request.usuario_id = state.activeUser.id
  },
  resetRealState: (state) => {
    Object.assign(state.realState, defaultNewRealState())
  },
  resetUser: (state) => {
    Object.assign(state.user, defaultNewUser())
  },

  setNewTenantFiles: (state, payload) => {
    payload.forEach(file => {
      const newFile = defaultNewTenantFile()
      state.newTenant.id = file.pessoa_id
      newFile.id = file.id
      newFile.nome_arquivo = file.nome_arquivo
      newFile.arquivo_url = file.arquivo_url
      newFile.extensao_arquivo = file.extensao_arquivo
      newFile.nome_documento = file.nome_documento
      state.newTenant.files.push(newFile)
    })
  },
  removeTenantFromTenants: (state, payload) => {
    const idx = state.request.tenants.findIndex((t, i) => {
      return t.id == payload
    })
    return state.request.tenants.splice(idx, 1)
  },

  setComplementaryDocumentTypeList: (state, payload) => {
    state.complementaryDocumentTypeList = payload
  },

  setAnalysisList: (state, payload) => {
    return state.analysisList = payload
  },
  setGeneratedDocumentsList: (state, payload) => {
    return state.generatedDocumentsList = payload
  },

  setRequiresNewAnalysis: (state, payload) => {
    state.requiresNewAnalysis = payload
  },
  setRealEstateConditionTerm: (state, payload) => {
    return state.realEstateConditionTerm = payload
  }, 
  setNacionalidadeList: (state, payload) => {
    return state.nacionalidadeList = payload
  }

}
